import service from "../service.js";

//查询备品备件分类信息
export const searchSpareClass = params => {
    return service({
      method: 'POST',
      url: '/spareClass/serchSpareClass',
      params
    })
}

//获取所有以及分类信息
export const querySpareOneClass = () => {
    return service({
      method: 'GET',
      url: '/spareClass/getListOne',
    })
}


//获取所有以及分类信息
export const getPareClassList = () => {
  return service({
    method: 'GET',
    url: '/spareClass/getListPare',
  })
}



//删除备品备件分类信息
export const delSpareClass = (id) => {
    return service({
      method: 'GET',
      url: '/spareClass/deleteSpareClass',
      params: {
        id
      }
    })
  }

export const addSpareClass = (data) => {
  return service({
    method: 'POST',
    url: '/spareClass/addSpareClass',
    data
  })
}

export const updateSpareClass = (data) => {
  return service({
    method: 'POST',
    url: '/spareClass/updateSpareClass',
    data
  })
}

export const querySpareClass = (id) => {
  return service({
    method: 'GET',
    url: '/spareClass/querySpareClass',
    params: {
      id
    }
  })
}
