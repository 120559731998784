import service from "../service.js";

//查询备品备件分类信息
export const searchSparePart = params => {
    return service({
      method: 'POST',
      url: '/spare/serchSparePart',
      params
    })
}

export const addSparePart = (data) => {
    return service({
      method: 'POST',
      url: '/spare/addSparepart',
      data
    })
  }
  
  export const updateSparePart = (data) => {
    return service({
      method: 'POST',
      url: '/spare/updateSparePart',
      data
    })
  }

  export const deleteSparePart = (id) => {
    return service({
      method: 'GET',
      url: '/spare/deleteSparePart',
      params: {
        id
      }
    })
  }

  export const querySparePart = (id) => {
    return service({
      method: 'GET',
      url: '/spare/querySparePart',
      params: {
        id
      }
    })
  }

  